import * as React from 'react';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';

import { Container } from '../../../layout/Container';
import { Heading } from '../../../ui/text/Heading';
import { Section } from '../../../layout/Section';
import { BackgroundLoad } from '../../../layout/BackgroundLoad';
import { useFadeTransition } from '../../../../utils/hooks/useFadeTransition';
import NoMatch from '../../404Old';

import { useQuery } from '@apollo/client';
import { DEPARTMENT_PRICELIST_QUERY } from '../../../../../client/__graphql__/cluster/queries/department/pricelist';
import { ENV_CONFIG } from '../../../../__config__/env';
import {
  DepartmentPricelistQuery,
  DepartmentPricelistQueryVariables
} from '../../../../__types__/generated/cluster';

const PricelistSale: React.FC = () => {
  const params = useParams<{ urltag: string }>();
  // TODO OFFICE cluster
  const { data, error, loading } = useQuery<
    DepartmentPricelistQuery,
    DepartmentPricelistQueryVariables
  >(DEPARTMENT_PRICELIST_QUERY, {
    fetchPolicy: 'no-cache',
    variables: {
      input: {
        alias: params.urltag,
        brandId: ENV_CONFIG?.BRAND_ID
      }
    }
  });
  const { fadeIn } = useFadeTransition({
    loaded: !loading && data?.department?.alias ? true : false
  });

  if (params.urltag === 'hovedkontor') {
    return <NoMatch />;
  }

  if (error?.graphQLErrors && error?.graphQLErrors?.length > 0) {
    return <NoMatch />;
  }

  return (
    <Container style={{ paddingTop: '100px', minHeight: '600px' }}>
      <Section style={fadeIn}>
        <Heading tag="h1" center={true}>
          PrivatMegleren {data?.department?.name}
        </Heading>
        <PricelistWrapper>
          <span
            dangerouslySetInnerHTML={{
              __html: data?.department?.texts?.pricelist ?? ``
            }}
          ></span>
        </PricelistWrapper>
      </Section>
      <BackgroundLoad
        opacity={0.3}
        position="center"
        url="https://cdn.reeltime.no/pm_assets/img/bakgrunner/hjort_bakside2.jpg"
      />
    </Container>
  );
};

const PricelistWrapper = styled.div`
  padding-bottom: 50px;
  float: left;
  .pristitle {
    color: ${(props) => props.theme.colors.primary};
    font-weight: normal;
    font-size: 1.3em;
    line-height: normal;
    margin-top: 1em;
    display: block;
  }
  ul {
    color: white;
  }
  table {
    color: white;
    tr {
      td {
        font-size: 0.8rem;
        padding: 0.8rem;
        width: 200px;
        border-bottom: 1px solid #181818;

        &:first-child {
          width: 800px;
        }
        &:last-child {
          color: ${(props) => props.theme.colors.primary};
        }
      }
    }
  }
`;

export default PricelistSale;
