import { gql } from '@apollo/client';

export const DEPARTMENT_PRICELIST_QUERY = gql`
  query DepartmentPricelist($input: DepartmentInput!) {
    department(input: $input) {
      alias
      brandId
      email
      name
      texts {
        ... on DepartmentTextsDefault {
          pricelist
        }
      }
    }
  }
`;
